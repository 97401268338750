import { PostgresTable, TableCluster, MutationOrder } from '@api/client'
import { useMemo } from 'react'
import {
  Card,
  HStack,
  ListItem,
  OrderedList,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  Heading,
  TabPanels,
  Tabs,
  Tag,
  VStack,
  Text,
  Code,
  Box,
} from '@chakra-ui/react'
import { MetricsCard } from '@app/features/tenants/components/metrics/metrics-card'
import { Section, SectionBody, SectionHeader } from '@saas-ui-pro/react'
import { useProjectPath } from '@app/features/core/hooks/use-path'
import { Link } from '@saas-ui/react'
import React from 'react'

export type TableClustersProps = {
  tableClusters?: TableCluster[]
}

const tabListProps: TabListProps = {
  overflow: 'hidden',
  borderTopRadius: 'md',
  display: 'flex',
  flexWrap: 'wrap',
}

const tableName = (pt: PostgresTable) => {
  if (pt.schema === 'public') return pt.name
  return `${pt.schema}.${pt.name}`
}

export type TableClusterWithMutationOrderProps = {
  tableCluster: TableCluster
}

const mutationOrderTypeToSqlPrefix = (type: string) => {
  switch (type) {
    case 'insert':
      return 'INSERT INTO '
    case 'update':
      return 'UPDATE '
    default:
      return 'UNKNOWN '
  }
}

const InterposeWithComma = ({ elements }: { elements: React.ReactNode[] }) => {
  return (
    <span>
      {elements.map((element, index) => (
        <React.Fragment key={index}>
          {element}
          {index < elements.length - 1 && ', '}
        </React.Fragment>
      ))}
    </span>
  )
}

const MutationOrderTab: React.FC<{ mutationOrder: MutationOrder }> = ({
  mutationOrder,
}) => {
  const { table, type, columns, returningColumns } = mutationOrder
  const basePath = useProjectPath('/schema-overview')

  const columnLinks = useMemo(() => {
    return columns.map((column) => (
      <Link
        key={column}
        href={basePath + `/schema/${table.schema}/${table.name}/${column}`}
      >
        {column}
      </Link>
    ))
  }, [basePath, columns, table.name, table.schema])

  const returningColumnsLinks = useMemo(() => {
    return returningColumns.map((column) => (
      <Link
        key={column}
        href={basePath + `/schema/${table.schema}/${table.name}/${column}`}
      >
        {column}
      </Link>
    ))
  }, [basePath, returningColumns, table.name, table.schema])

  return (
    <ListItem mb={2}>
      <Code>{`${mutationOrderTypeToSqlPrefix(type)} ${tableName(table)}`}</Code>
      <Code>
        {type == 'update' ? (
          <Box as="span">
            {'SET '}
            <InterposeWithComma elements={columnLinks} />
          </Box>
        ) : (
          <Box as="span">
            (<InterposeWithComma elements={columnLinks} />)
          </Box>
        )}
      </Code>
      {returningColumns.length > 0 && (
        <Code>
          RETURNING (<InterposeWithComma elements={returningColumnsLinks} />)
        </Code>
      )}
    </ListItem>
  )
}

const MutationOrdersByType: React.FC<{
  mutationOrders: MutationOrder[]
}> = ({ mutationOrders }) => {
  return (
    <>
      <Heading size="sm" mb={2}>
        Mutation Ordering
      </Heading>
      <OrderedList>
        {mutationOrders.map((mutationOrder, index) => (
          <MutationOrderTab key={index} mutationOrder={mutationOrder} />
        ))}
      </OrderedList>
    </>
  )
}

const TableClusterWithMutationOrder: React.FC<{
  tableCluster: TableCluster
}> = ({ tableCluster }) => {
  if (tableCluster.tables.length <= 1)
    return (
      <Section variant="annotated">
        <SectionHeader title={tableName(tableCluster.tables[0])} />
        <SectionBody>
          <MutationOrdersByType mutationOrders={tableCluster.mutationOrders} />
        </SectionBody>
      </Section>
    )

  return (
    <Section variant="annotated">
      <SectionHeader title={`${tableCluster.tables.length} tables`} />
      <SectionBody>
        <MutationOrdersByType mutationOrders={tableCluster.mutationOrders} />
      </SectionBody>
    </Section>
  )
}

export const TableClusters = ({ tableClusters }: TableClustersProps) => {
  const loners = useMemo(() => {
    if (!tableClusters) return []
    return tableClusters.filter((tc) => tc.tables.length <= 1)
  }, [tableClusters])

  const nonLoners = useMemo(() => {
    if (!tableClusters) return []
    return tableClusters
      .filter((tc) => tc.tables.length > 1)
      .sort((a, b) => {
        return a.tables.length - b.tables.length
      })
  }, [tableClusters])

  return (
    <MetricsCard title="Table clusters" mb={10}>
      <Tabs>
        <TabList {...tabListProps}>
          {nonLoners.map((tc) => (
            <Tab key={tc.id}>{tc.tables.length} tables</Tab>
          ))}
          <Tab>
            <HStack alignItems="center">
              <Text>Loners</Text>
              <Tag>{loners.length}</Tag>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          {nonLoners.map((tc) => (
            <TabPanel key={tc.id}>
              <TableClusterWithMutationOrder key={tc.id} tableCluster={tc} />
            </TabPanel>
          ))}
          <TabPanel>
            {loners.map((tc) => (
              <TableClusterWithMutationOrder key={tc.id} tableCluster={tc} />
            ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MetricsCard>
  )
}
