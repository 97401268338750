import {
  Environment,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import {
  Box,
  Button,
  Code,
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  ListItem,
  OrderedList,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react'

import { Form, useSnackbar } from '@saas-ui/react'
import {
  EnvironmentSelect,
  MinimalEnvironment,
} from '@app/features/tenants/components/datasources/environment-select'
import { useMemo, useState } from 'react'
import {
  CreateDatasourceFormInput,
  createDatasourceSchema,
  defaultPgUrl,
} from '@app/features/tenants/pages/environments/view'
import {
  ASSIGN_PG_TUNNEL_TO_ENVIRONMENT,
  CREATE_ENVIRONMENT_PG_DATASOURCE,
} from '@api/client'
import { useMutation } from '@apollo/client'
import {
  GreenCheck,
  OnboardingWizardComponentProps,
  OnboardingWizardStep,
} from './shared'
import { ratholeConfigFileName } from '@app/features/tenants/pages/environments/setup-pgtunnel'
import { FiCopy } from 'react-icons/fi'

const tabProps: TabProps = {
  alignItems: 'stretch',
  justifyContent: 'stretch',
  flex: '1 0 auto',
  height: 'auto',
  textAlign: 'left',
  borderBottomWidth: '1px',
  borderRightWidth: '1px',
  _hover: {
    bg: 'whiteAlpha.100',
  },
  _selected: {
    borderBottomWidth: '2px',
    borderBottomColor: 'primary.500',
    display: 'flex',
  },
  _last: {
    borderRightWidth: '0',
  },
}

const tabListProps: TabListProps = {
  overflow: 'hidden',
  borderTopRadius: 'md',
  display: 'flex',
  flexWrap: 'wrap',
}

type PgTunnelPort = Environment['pgtunnelPorts'][0]

const DownloadPgTunnelConfig: React.FC<{
  environment: Environment
  pgtunnelPort: PgTunnelPort
}> = ({ environment, pgtunnelPort }) => {
  const [downloaded, setDownloaded] = useState(false)
  const ratholeTomlFile = ratholeConfigFileName(environment, pgtunnelPort.port)

  return (
    <VStack
      spacing="1rem"
      alignItems={'flex-start'}
      width={'full'}
      flexGrow={1}
    >
      {!downloaded ? (
        <Button
          size="md"
          variant="primary"
          onClick={() => {
            const a = document.createElement('a')

            const file = encodeURIComponent(
              [
                '[client]',
                `remote_addr = "pgtunnel.eu.schemamap.io:2333"`,
                '',
                `[client.services.postgres-${pgtunnelPort.port}]`,
                `token = "${pgtunnelPort.token}" # secret, do not share`,
                `local_addr = "127.0.0.1:5432" # The postgres port to be forwarded, edit if custom`,
              ].join('\n'),
            )

            a.href = `data:text/plain;charset=utf-8,${file}`
            a.download = ratholeTomlFile
            a.click()
            setDownloaded(true)
          }}
        >
          Download secure tunnel configuration
        </Button>
      ) : (
        <Box>
          <Box>
            Please edit the file to make sure your Postgres port (5432) is
            correct, then run:
          </Box>
          <CodeCopy
            textToCopy={`schemamap up -f ${ratholeTomlFile}`}
            onCopyText={'Copied TCP tunneling command'}
          >
            <Link isExternal href="https://github.com/schemamap/schemamap">
              schemamap up
            </Link>{' '}
            -f {ratholeTomlFile}
          </CodeCopy>
        </Box>
      )}
    </VStack>
  )
}

export const CodeCopy = ({
  children,
  textToCopy,
  onCopyText,
}: {
  children?: React.ReactNode
  textToCopy: string
  onCopyText: string
}) => {
  const snackbar = useSnackbar()
  const { onCopy } = useClipboard(textToCopy)
  return (
    <Code
      mt={2}
      display={'flex'}
      alignItems={'center'}
      py={4}
      px={4}
      borderRadius={'5px'}
      cursor={'pointer'}
      onClick={() => {
        onCopy()
        snackbar.info(onCopyText)
      }}
      title={'Copy to clipboard'}
    >
      <Text mr={10}>{children ?? textToCopy}</Text>
      <IconButton
        marginLeft={'auto'}
        size="lg"
        icon={<FiCopy />}
        aria-label="Copy to clipboard"
      ></IconButton>
    </Code>
  )
}

export const ConnectEnvironmentStep: React.FC<
  OnboardingWizardComponentProps
> = (props) => {
  const { completed } = props
  const { project, invalidate: refetch } = useCurrentUser()
  const { environments } = project ?? {}
  const snackbar = useSnackbar()

  const [assignPgTunnelPort, { loading: isReservingTunnel }] = useMutation(
    ASSIGN_PG_TUNNEL_TO_ENVIRONMENT,
    {
      onCompleted: (data) => {
        refetch()
        snackbar.success(`Tunnel reserved! 🎉`)
      },
      onError: (error) => {
        snackbar.error(error.message)
      },
    },
  )

  const notConnectedEnvironments = useMemo(() => {
    return (
      environments?.filter((env) => (env.datasources?.length ?? 0) == 0) ?? []
    )
  }, [environments])

  const [selectedEnv, selectEnv] = useState<MinimalEnvironment | null>(
    notConnectedEnvironments?.[0] ?? null,
  )

  const environment = useMemo(() => {
    return environments?.find((env) => env.id === selectedEnv?.id)
  }, [environments, selectedEnv?.id])

  const pgtunnelPorts = environment?.pgtunnelPorts ?? []
  const pgtunnelPort = pgtunnelPorts.length > 0 ? pgtunnelPorts[0] : undefined

  const [createDS, { loading: isCreatingDS }] = useMutation(
    CREATE_ENVIRONMENT_PG_DATASOURCE,
    {
      onCompleted: (data) => {
        refetch()
        snackbar.success(
          `Connected to Postgres environment ${environment?.name} successfully`,
        )
      },
      onError: (error) => {
        snackbar.error(
          'Failed to create datasource: ' + error.graphQLErrors[0].message,
        )
      },
    },
  )

  const defaultConnectionFormValues: CreateDatasourceFormInput = useMemo(() => {
    return { connectionString: defaultPgUrl(pgtunnelPort?.port ?? 5432) }
  }, [pgtunnelPort?.port])

  if (!environment) return null

  return (
    <OnboardingWizardStep
      {...props}
      title={
        completed ? (
          'Connected to your first Postgres environment 🎉'
        ) : (
          <HStack>
            <Box as={'span'}>Connect to your</Box>{' '}
            <EnvironmentSelect
              name="environment"
              environments={notConnectedEnvironments ?? []}
              selectedEnvironment={selectedEnv}
              onSelectedEnvironmentChange={selectEnv}
            />{' '}
            <Box as={'span'}>Postgres DB</Box>
          </HStack>
        )
      }
    >
      <VStack
        spacing="1rem"
        alignItems={'flex-start'}
        width={'full'}
        flexGrow={1}
      >
        <Heading size="sm">
          1. Install the Postgres SDK into your Postgres DB
        </Heading>
        <Tabs variant="unstyled">
          <TabList {...tabListProps}>
            <Tab {...tabProps}>CLI (secure, ~1min)</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px={0} w="full">
              <CodeCopy
                textToCopy="brew install schemamap/tap/schemamap && schemamap init"
                onCopyText="Copied CLI installer"
              >
                brew install schemamap/tap/schemamap && schemamap init
              </CodeCopy>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Divider my={'1rem'} />

        <Heading size="sm">
          2. Allow Schemamap.io to reach your Postgres DB
        </Heading>
        <Tabs variant="unstyled">
          <TabList {...tabListProps}>
            <Tab {...tabProps}>
              {pgtunnelPort?.port ? (
                <>
                  Reserved P2P Port: {pgtunnelPort.port} <GreenCheck />
                </>
              ) : (
                <>P2P Secure TCP tunnel</>
              )}
            </Tab>

            <Tab {...tabProps}>Internet (SSL)</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px={0}>
              {pgtunnelPort ? (
                <DownloadPgTunnelConfig
                  environment={environment}
                  pgtunnelPort={pgtunnelPort}
                />
              ) : (
                <Button
                  size="md"
                  variant="primary"
                  isLoading={isReservingTunnel}
                  onClick={() => {
                    assignPgTunnelPort({
                      variables: {
                        environmentId: environment.id,
                      },
                    })
                  }}
                >
                  Reserve a tunnel for this environment
                </Button>
              )}
            </TabPanel>
            <TabPanel px={0}>
              {' '}
              <Text textColor={'muted'}>
                Connect directly over the internet, whitelist this IP for your
                firewall:{' '}
                <CodeCopy
                  textToCopy={process.env.NEXT_PUBLIC_DB_WHITELIST_IPS ?? ''}
                  onCopyText={'Copied IP whitelist'}
                >
                  {process.env.NEXT_PUBLIC_DB_WHITELIST_IPS}
                </CodeCopy>
              </Text>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Divider my={'1rem'} />

        <Heading size="sm">3. Connect with "schemamap" user</Heading>
        <Text>
          Now that:
          <OrderedList my={2}>
            <ListItem>Secure network connectivity is possible</ListItem>
            <ListItem>
              The <Code>schemamap</Code> schema and users are set up, with
              minimal permissions
            </ListItem>
          </OrderedList>
          It's time to connect with the following connection string:
        </Text>
        <Text>
          Make sure to double check the port and database name in the
          connection!
        </Text>
        <Form
          key={defaultConnectionFormValues.connectionString}
          width={'full'}
          schema={createDatasourceSchema}
          defaultValues={defaultConnectionFormValues}
          onSubmit={(data) => {
            if (!environment) return
            createDS({
              variables: {
                connectionString: data.connectionString,
                environmentId: environment.id.toString(),
              },
            })
          }}
        >
          {({ Field }) => (
            <>
              <Field name="connectionString" />
              <Button
                variant="solid"
                colorScheme="primary"
                type="submit"
                isLoading={isCreatingDS}
                mt={'1rem'}
              >
                Connect
              </Button>
            </>
          )}
        </Form>
      </VStack>
    </OnboardingWizardStep>
  )
}
